<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <h2 class="text-center">Mars Ant Motors CMS</h2>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form v-model="formValid" :disabled="processing" @submit.prevent="formValid && submitLogin()">
            <v-text-field
              v-model="username"
              class="mb-2"
              outlined
              label="電郵"
              :rules="[v => !!v || '請輸入電郵']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密碼"
              :append-icon="isPasswordVisible ? icon.mdiEyeOffOutline : icon.mdiEyeOutline"
              :rules="[v => !!v || '請輸入密碼']"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              depressed
              :loading="processing"
              :disabled="!formValid"
              block
              elevation="0"
              color="primary"
              class="mt-6"
              type="submit"
              @click="formValid && submitLogin()"
              >登入</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { defaultPage } from '@/assets/cannotAccessList'

export default {
  name: 'Login',
  computed: {
    processing() {
      return this.$store.getters.isLoading
    }
  },
  setup() {
    return {
      icon: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    formValid: false,
    isPasswordVisible: false,
    username: '',
    password: '',
  }),

  methods: {
    async submitLogin() {
      if (this.processing) {
        return;
      }

      if (!this.$validate.DataValid(this.username) || !this.$validate.DataValid(this.password)) {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: 'required',
        })
        return
      }

      this.$store.dispatch('setLoading', true)

      try {
        const data = await this.$Fetcher.Login(this.username, this.password);
        await this.setUserData({
          id: data.id,
          user_type: data.user_type,
          last_login: new Date().getTime(),
          username: data.name,
          token: data.cms_token
        })

        if (this.$validate.DataValid(defaultPage[data.user_type])) {
          this.$router.replace(defaultPage[data.user_type]);
        }
      } catch(err) {
        this.$common.error(err)
        this.$store.dispatch('toggleSnack', {
          show: true,
          type: 'error',
          message: '帳戶或密碼不正確',
        })
        this.$store.dispatch('setLoading', false)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
